import React from 'react';
import { PopupButton } from 'react-calendly';


const PopupComponent = () => {
  return (
    <div>
      <PopupButton
        url="https://calendly.com/darko-bozhinoski/meeting-with-scivenia"
        rootElement={document.getElementById('root')}
        text="Schedule Time with CEO"
        className="mt-1 block w-full rounded-md bg-gray-800 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm focus:outline-none focus:ring focus:ring-gray-700 overflow-hidden"
      />
    </div>
  );
};

export default PopupComponent;

