import React from "react";

function Card({ imageUrl, title, description, buttonText, buttonUrl, text, backgroundColor, imageUrlP }) {
  return (
  <>
  <div className={`cardProject mr-0 sm:mr-5 flex flex-row w-full w-[360px] sm:w-[700px] h-[400px] mx-0 sm:mx-2 border rounded-lg shadow ${backgroundColor} border-gray-100`}>
      <div className="flex flex-col content-between justify-items-stretch justify-between py-5">
        <a href={buttonUrl}>
          <img className="rounded-t-lg px-3 pt-1 max-w-[200px] max-h-[40px] min-w-[160px] " src={imageUrl} alt="" />
        </a>
        <div className="px-3 pb-5 max-w-[400px] sm:min-w-[200px] md:min-w-[380px] lg:max-w-[340px]">
            <h5 className="text-xl font-semibold tracking-tight text-gray-900 dark:text-white py-10">
              {description}
            </h5>
        </div>

        <div className="flex px-3">
        <div className="pr-2">
          <a
            href={buttonUrl}
            target="blank"
            rel="noopener noreferrer"
            className="text-white font-medium rounded-lg text-md py-2.5 text-center flex flex-col"
          >
            Learn More
          </a>
        </div>
        <div className="bg-white rounded-full pl-0 w-10 h-10 arrow">
            <a
              href={buttonUrl}
              target="blank"
              rel="noopener noreferrer"
            >
              <svg className="w-6 h-6 mt-2 ml-2 p-2 text-gray-800 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 8 14">
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 13 5.7-5.326a.909.909 0 0 0 0-1.348L1 1"/>
              </svg>
            </a>
        </div>
  
      </div>
      </div>

      <div className="p-2 flex self-center rounded-t-lg max-w-[300px] sm:min-w-[120px] md:min-w-[200px] lg:max-w-[300px] max-h-[100px] items-center imgProject">
        <img src={imageUrlP} alt="" />
      </div>
  </div>
  </>    
  );
}

export default Card;