import product_screenshot from "../../assets/images/product-screenshot.jpg";

export default function Intro() {

  return (
    <>
      <div className="overflow-hidden relative" id="home">
        <div className="bgWhite1"></div>
        <div className="mx-auto max-w-7xl px-6 lg:px-8 py-20 sm:py-40">
          <div className="mx-auto grid place-items-center grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:grid-cols-2">
            <div className="">
              <div className="">
                <p className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">Developing software
                  solutions on budget and on time</p>
                <p className="mt-6 text-lg leading-8 text-gray-600">
                  Develop a digital strategy to automate complex business processes!
                </p>
                <div className="mt-10 flex items-start gap-x-6  sm:pb-5 pb-4">
                  <a
                    href="#contact"
                    className="rounded-md bg-gray-800 px-6 py-2.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
                  >
                    Get started
                  </a>
                </div>
              </div>
            </div>
            <img
              src={product_screenshot}
              alt="Product screenshot"
              className="w-[30rem] rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[40rem]"
              width={2432}
              height={1442}
            />
          </div>
        </div>
      </div>
    </>
  )
}