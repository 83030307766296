export default function StatContainer({id, value, name}) {
  
  return (
    <div className="bg-gray-900 py-12 sm:py-18">
      <div className="px-6 lg:px-40">
        <dl className="text-start">
            <div key={id} className="flex items-center flex-col">
              <dt className="text-base leading-7 text-white">{name}</dt>
              <dd className="order-first text-3xl font-semibold tracking-tight text-white sm:text-4xl">
                {value}
              </dd>
            </div>
        </dl>
      </div>
    </div>
  );
}


