import Layout from '../layout/Layout';
import IntroContainer from "../home/IntroContainer";
import ContainerExpertise from "../services/ContainerExpertise";
import ContainerWeOffer from "../services/ContainerWeOffer.jsx";
import Carousel from "../projects/CarousleSection";
import SciVenia from "../projects/SciVeniaSection";
import Team from "../team/TeamContainer";
import Footer from '../../components/molecules/Footer';

function App() {
  return (
    <div className="App">
      <Layout />
      <div>
      <IntroContainer/>   
      <ContainerExpertise/>
      <ContainerWeOffer/>
      <Carousel/>
      <SciVenia/>
      <Team/>
      <Footer />
      </div>
    </div>
  );
}

export default App;