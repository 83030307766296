import StatContainer from "../../components/StatContainer";

const features = [
  {
    shortText: 'Web Development',
    name: 'From Simple Presentations to Complex Web Application',
    description:
      'TechVenia offers effective web experiences that delight visitors and meet business requirements ranging from simple presentation and e-commerce websites to complex web applications.',
    img: "https://img.freepik.com/free-vector/digital-designers-team-drawing-with-pen-computer-monitor_74855-10586.jpg?size=626&ext=jpg",
  },
  {
    shortText: 'Automation',
    name: 'Simplified Automation for Streamlined Operations',
    description:
      'Remove technical and organisational friction through efficient automation. We will help you create a digital stragety for your company to automate complex business processes.',
    img: "https://img.freepik.com/free-vector/child-getting-award-robot_74855-6297.jpg?w=1060&t=st=1687353495~exp=1687354095~hmac=390ddf26da1e1b1daf1db50a6d386f4ba83cc01e7023017d71914bed7fbb4375",
  },
  {
    shortText: 'Build your own team ',
    name: 'Flexible and Scalable Development Teams Tailored to Your Needs',
    description:
      'Scale your development team on demand. Have your team at your own terms. You get dedicated engineers fully aligned to your working processes.',
    img: "https://img.freepik.com/free-vector/business-team-meeting-office-co-working-space_74855-6913.jpg?w=1060&t=st=1687353540~exp=1687354140~hmac=8f986d2291a67df58c855b1426ec661bfe33a58cc993f03101d0a08c6ec78607",
  },
  {
    shortText: 'AI Services ',
    name: 'Unlock the Power of Intelligence in Your Applications',
    description:
      'We provide state of the art tools to easily add intelligence to your existing applications. Our solutions address personalized recommendations and increase customer engagement.',
    img: "https://img.freepik.com/free-vector/children-presenting-cyborg-robotics-class_74855-5457.jpg?w=996&t=st=1687353619~exp=1687354219~hmac=08239ccf0281257ac4f31eb11533f63a54f2cac2233293699b2a09bd527597e4https://toppageworld.com/wp-content/uploads/2021/11/WEB-DEVELOPMENT.png",
  },
  {
    shortText: 'Conversational Agents ',
    name: 'Transform Customer Service and Boost Contact Center Efficiency',
    description:
      'Easily build conversational agents to improve customer service and increase contact center efficiency.',
    img: "https://img.freepik.com/free-vector/team-leader-teamwork-concept_74855-6671.jpg?w=996&t=st=1687354272~exp=1687354872~hmac=e79129c7cc7b6ab14c420ba08237927a5c7de64b145f4179e4f1ebe36f208809",
  },
  {
    shortText: 'Forecasting and recommendation services ',
    name: 'Enhance Customer Experiences with Personalization and Accurate Forecasting',
    description:
      'Personalize experiences and build accurate forecasting models for your customers using machine learning techniques.',
    img: "https://img.freepik.com/free-vector/manager-presenting-report-colleagues-partners-investors-diagram-bar-chart-graph-flat-vector-illustration-business-presentation-analysis_74855-8460.jpg?w=996&t=st=1687365039~exp=1687365639~hmac=3c12776822f21eaf64633702e109bc4b817629044bceda52cc2c4bd6f89a13a7",
  },
]

export default function Example() {
    return (
      <>
      <div id="services"></div>
      <StatContainer
      name= ''
      value= 'Our expertise'
      />
      <div className="overflow-hidden bg-white py-1 sm:py-2">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto grid place-items-center">
          {features.map((feature, index) => (
            <div
              key={index}
              className={`mx-auto grid place-items-center py-10 grid-cols-1 gap-x-10 gap-y-16 sm:gap-y-20 lg:grid-cols-2 ${
                index % 2 === 1 ? 'lg:flex-row-reverse' : ''}`}
            >
              <div className={`lg:pt-4 ${index % 2 === 1 ? 'lg:order-2' : ''}`}>
                <div className="px-0">
                  <h2 className="text-xs font-semibold leading-7 text-gray-600">{feature.shortText}</h2>
                  <p className="text-1xl font-bold tracking-tight text-gray-900 sm:text-2xl"> {feature.name} </p>
                  <p className="mt-6 text-md leading-7 text-gray-600">
                    {feature.description}
                  </p>
                </div>
              </div>
              <div className={`flex ${index % 2 === 1 ? 'lg:order-1' : ''}`}>
                <img
                  src={feature.img}
                  alt="Product screenshot"
                  className="w-[35rem] rounded-xl shadow-xl ring-1 ring-gray-400/10 h-[16rem] sm:h-[20rem]"
                  width={2432}
                  height={1442}
                />
              </div>
            </div>
          ))}
          </div>
        </div>
      </div>

      </>
    )
  }