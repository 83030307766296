import { CheckBadgeIcon } from '@heroicons/react/20/solid';
import img from "../../assets/images/Scientist_Profile.jpg";

const features = [
  {
    name: 'Endless events: ',
    description:
      "SciVenia is an online application platform that connects organisers to scientists. It allows for organisers to easily create events and scientists to easily apply for them.",
    icon: CheckBadgeIcon,
  },
  {
    name: 'At the service of scientists:',
    description:
      'As a scientist you can apply for all kinds of events, from conferences and workshops to competitions and more. By providing information on your location, event types, as well as your area of expertise, SciVenia will offer only those events relevant to them, avoiding the information overload that currently exists online.',
    icon: CheckBadgeIcon,
  },
  {
    name: 'And of organisers:',
    description:
      'SciVenia, as a platform for organisers, gives you the possibility to promote your events to a large number of scientists, to receive and manage applications through an easy-to-use tool and even take/make payments through a safe online system. Organisers could be established organisations looking for applicants for a competition or a workshop. They could also be a group of scientists creating an event in their niche area of expertise.',
    icon: CheckBadgeIcon,
  },

]

export default function Example() {
  return (
    <> 

    <div className="overflow-hidden bg-white py-2 sm:py-8">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid place-items-center grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:grid-cols-2">
          
            <div className="">
              <h2 className="text-base font-semibold leading-7 text-gray-600">Learn about our Product:</h2>
              <p className="text-base mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">SciVenia</p>

              <dl className="sm:mt-8 mt-2 space-y-10 text-base leading-7 text-gray-600 ">
                {features.map((feature) => (
                  <div key={feature.name} className="relative pl-9">
                    <dt className="inline font-semibold text-gray-900">
                      <feature.icon className="absolute left-1 top-1 h-5 w-5 text-indigo-600" aria-hidden="true" />
                      {feature.name}
                    </dt>{' '}
                    <dd className="inline">{feature.description}</dd>
                  </div>
                ))}
              </dl>
            </div>
          
          <img
            src={img}
            alt="Product screenshot"
            className="w-[30rem] rounded-xl shadow-md sm:shadow-xl ring-1 ring-gray-400/10 sm:w-[40rem]"
            width={2432}
            height={1442}
          />
        </div>
      </div>
    </div>

    </>
  )
}