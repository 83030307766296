import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
import Card from "./ProjectCards";

function Carousel() {

    const scrollLeft = () => {
        const contentElement = document.getElementById("content");
        if (window.innerWidth < 768) {

          contentElement.scrollLeft -= 350;
        } else if (window.innerWidth < 1024) {

          contentElement.scrollLeft -= 710;
        } else {

          contentElement.scrollLeft -= 600;
        }
      };
      
      const scrollRight = () => {
        const contentElement = document.getElementById("content");
        if (window.innerWidth < 768) {

          contentElement.scrollLeft += 350;
        } else if (window.innerWidth < 1024) {

          contentElement.scrollLeft += 710;
        } else {

          contentElement.scrollLeft += 600;
        }
      };


  return (
    <>
    <div id="projects"></div>
    <div className="relative py-10 pl-0" >
      <div className="text-center py-4 text-4xl sm:text-5xl font-bold">Our projects</div>
      <div className="absolute right-0 top-11 ">
        <button onClick={scrollLeft} className="p-2 m-2 rounded-full bg-white">
          <FiChevronLeft />
        </button>
        <button onClick={scrollRight} className="p-2 m-2 rounded-full bg-white">
          <FiChevronRight />
        </button>
      </div>
      <div id="content" className="carousel p-0 sm:p-4 flex items-center justify-start overflow-x-hidden scroll-smooth  scrollbar-hide ">
        <div> 
          {/* // THIS SHOUL BE TEMPLATE */}
        <Card
            imageUrl="https://scivenia.com/images/sv_logo_2.png"
            title="SciVenia"
            description="The easiest way to manage events"
            text="SciVenia is an online application platform that connects organisers to scientists. It allows for organisers to easily create events and scientists to easily apply for them."
            buttonUrl="https://scivenia.com/en/about"
            backgroundColor="bcg1"
            imageUrlP="https://horizonte.as/wp-content/uploads/2017/06/iPad-Pro-9.7-White-Mockup-min.png"
            /> 
        </div>
        <div>
        <Card
            imageUrl="https://www.mubazar.com/_next/image?url=%2Fimages%2Fmubazar-logo-final-white.png&w=256&q=75"
            title="Mubazar"
            description="Amazing opportunities for musicians!"
            text="It connects organizers to musicians locally and internationally. It allows organizers to create opportunities easily and musicians to register easily."
            buttonUrl="https://www.mubazar.com/en/about"
            backgroundColor="bcg2"
            imageUrlP="https://horizonte.as/wp-content/uploads/2017/06/iPad-Pro-9.7-White-Mockup-min.png"
            />
        </div>
        <div>
        <Card
            imageUrl="https://www.gemone.com/wp-content/themes/gemone-theme/assets//images/svg/logo-black.svg"
            title="GemOne"
            description="Smart telematics to "
            text="Get efficient fleet management with GemOne. Our team delivers a system that both meets your company’s needs today and responds to the challenges your business will face tomorrow."
            buttonUrl="https://www.gemone.com/en/about-us/"
            backgroundColor="bcg3"
            imageUrlP="https://horizonte.as/wp-content/uploads/2017/06/iPad-Pro-9.7-White-Mockup-min.png"
            />
        </div>
        <div>
        <Card
            imageUrl="http://europe-at-school.eu/wp-content/uploads/2019/07/cropped-Logo-Horizontal-01.png"
            title="Europe at school"
            description="Making young people aware of European citizenship"
            text="Europe at school activities aim at raising awareness of European citizenship and encouraging active European citizenship among young people."
            buttonUrl="https://europe-at-school.eu/en/about-us/"
            backgroundColor="bcg1"
            imageUrlP="https://horizonte.as/wp-content/uploads/2017/06/iPad-Pro-9.7-White-Mockup-min.png"
            />
        </div>
        <div>
        <Card
            imageUrl="https://grid.mk/img/brand/logo.png"
            title="Grid"
            description="Latest news from Macedonia and the world."
            text="Latest news from Macedonia and the world. The best Macedonian televisions and portals in one place. Politics, Sports, Culture and other interesting contents."
            buttonUrl="https://grid.mk/"
            backgroundColor="bcg2"
            imageUrlP="https://horizonte.as/wp-content/uploads/2017/06/iPad-Pro-9.7-White-Mockup-min.png"
            />
        </div>

        <div>
        <Card
            imageUrl="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjFlbSIgaGVpZ2h0PSIxZW0iIHZpZXdCb3g9IjAgMCAzMiAzMiIgcm9sZT0iaW1nIiBmaWxsPSJjdXJyZW50Q29sb3IiIGNvbXBvbmVudG5hbWU9Im9yY2hpZC1pY29uIj4KICAgIDxwYXRoIGQ9Ik0yOS41IDdjLTEuMzgxIDAtMi41IDEuMTItMi41IDIuNSAwIDAuMjg0IDAuMDU4IDAuNTUxIDAuMTQ0IDAuODA1bC02LjA5NCA1LjI0N2MtMC40MjctMC4zNDEtMC45NjEtMC41NTMtMS41NS0wLjU1My0wLjY4IDAtMS4yOTQgMC4yNzMtMS43NDQgMC43MTNsLTQuNzc0LTIuMzljLTAuMDkzLTEuMjk2LTEuMTYyLTIuMzIzLTIuNDgyLTIuMzIzLTEuMzggMC0yLjUgMS4xMi0yLjUgMi41IDAgMC4zNzggMC4wOTAgMC43MzIgMC4yNCAxLjA1M2wtNC44NjcgNS42MTJjLTAuMjczLTAuMTAyLTAuNTY0LTAuMTY2LTAuODczLTAuMTY2LTEuMzgxIDAtMi41IDEuMTE5LTIuNSAyLjVzMS4xMTkgMi41IDIuNSAyLjVjMS4zODEgMCAyLjUtMS4xMTkgMi41LTIuNSAwLTAuMzMyLTAuMDY4LTAuNjQ5LTAuMTg2LTAuOTM5bDQuOTQ2LTUuNjg1YzAuMjM2IDAuMDczIDAuNDggMC4xMjQgMC43NCAwLjEyNCAwLjcyNyAwIDEuMzc3LTAuMzE2IDEuODM0LTAuODEzbDQuNjY5IDIuMzQxYzAuMDE3IDEuMzY3IDEuMTI3IDIuNDcxIDIuNDk3IDIuNDcxIDEuMzgxIDAgMi41LTEuMTE5IDIuNS0yLjUgMC0wLjA0NC0wLjAxMS0wLjA4Ni0wLjAxMy0wLjEzbDYuNTAzLTUuNTg3YzAuMzA5IDAuMTM3IDAuNjQ5IDAuMjE2IDEuMDEwIDAuMjE2IDEuMzgxIDAgMi41LTEuMTE5IDIuNS0yLjVzLTEuMTE5LTIuNS0yLjUtMi41eiIvPgo8L3N2Zz4="
            title="ABF"
            description="Project Management Tool"
            text="Latest news from Macedonia and the world. The best Macedonian televisions and portals in one place. Politics, Sports, Culture and other interesting contents."
            buttonUrl="https://dashboard.abf.vicre.eu/"
            backgroundColor="bcg3"
            imageUrlP="https://horizonte.as/wp-content/uploads/2017/06/iPad-Pro-9.7-White-Mockup-min.png"
            />
        </div>
      </div>
    </div>
    </>
  );
}

export default Carousel;