import { CalendarDaysIcon, HandRaisedIcon } from '@heroicons/react/24/outline';
import Modal from "../../atoms/MessageModal";
import Calendly from "../../atoms/CalendlyModal";
import Subscribe from '../../atoms/Subscribe';
import ContactUs from '../../atoms/ContactUs';

export default function Example() {



  return (
<>
    <div className="relative isolate overflow-hidden bg-gray-900 py-12 sm:py-20 lg:py-30">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid  grid-cols-1 gap-x-8 gap-y-16  lg:grid-cols-2">
          <Subscribe/>
          <dl className="grid grid-cols-1 gap-x-8 gap-y-10 sm:grid-cols-2 lg:pt-2">
            <div className="flex flex-col items-start">
              <div className="rounded-md bg-white/5 p-2 ring-1 ring-white/10">
                <CalendarDaysIcon className="h-6 w-6 text-white" aria-hidden="true" />
              </div>
              <dt className="mt-4 font-semibold text-white">Weekly articles</dt>
              <dd className="mt-2 leading-7 text-gray-400">
              Stay informed and inspired with thought-provoking content covering a variety of topics.
              </dd>
            </div>
            <div className="flex flex-col items-start">
              <div className="rounded-md bg-white/5 p-2 ring-1 ring-white/10">
                <HandRaisedIcon className="h-6 w-6 text-white" aria-hidden="true" />
              </div>
              <dt className="mt-4 font-semibold text-white">No spam</dt>
              <dd className="mt-2 leading-7 text-gray-400">
                Just quality content. Dive into a world of valuable insights and captivating reads.
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>


    <div className="relative isolate overflow-hidden bg-white" id='contact'>
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        
        <div className="mx-auto lg:flex ">
          <div className="pt-1 sm:pt-10 lg:flex-auto">
            <h3 className="text-2xl font-bold tracking-tight text-gray-900">TECHVENIA</h3>
            <p className="mt-6 text-base leading-7 text-gray-600">
            We develop software in a flexible way on budget and on time. Schedule an appointment with our CEO to find out more!
            </p>
            <ContactUs/>
          </div>
          <div className="-mt-2 p-0 sm:p-2 lg:mt-10 lg:w-full lg:max-w-md lg:flex-shrink-0 pt-10">
            <div className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-8">
              <div className="mx-auto max-w-xs px-8">
                <p className="text-base font-semibold text-gray-600">Social</p>
                <p className="mt-2 text-xs leading-5 text-gray-600">
                  Get in touch on our social media
                </p>
                <p className="mt-6 flex items-baseline justify-center gap-x-2">
                  {/* <span className="text-5xl font-bold tracking-tight text-gray-900">$349</span> */}
                  <span className="text-sm font-semibold leading-6 tracking-wide text-gray-600 flex"> 
                  <a href='https://twitter.com/techvenia' target='_blank' rel="noreferrer">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 mx-2"
                    fill="currentColor"
                    viewBox="0 0 24 24">
                    <path
                      d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" />
                  </svg>
                  </a>

                  <a href='https://www.instagram.com/techvenia/' target='_blank' rel="noreferrer">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 mx-2"
                    fill="currentColor"
                    viewBox="0 0 24 24">
                    <path
                      d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
                  </svg>
                  </a>

                  <a href='https://www.facebook.com/techvenia' target='_blank' rel="noreferrer">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 mx-2"
                    fill="currentColor"
                    viewBox="0 0 24 24">
                    <path
                      d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z" />
                  </svg>
                  </a>

                  <a href='https://www.linkedin.com/company/it-solutionsmk/' target='_blank' rel="noreferrer">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 mx-2"
                    fill="currentColor"
                    viewBox="0 0 24 24">
                    <path
                      d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z" />
                  </svg>
                  </a>
                </span>
                </p>

                <Modal/>
                <Calendly/>

 
                <p className="mt-6 text-xs leading-5 text-gray-600">
                  Invoices and receipts available for easy company reimbursement
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="mx-auto max-w-2xl text-center sm:text-center">
        <p className="text-xs text-gray-400">
        <a href="https://www.freepik.com/author/pch-vector" target='blank'>Images by pch.vector</a>,</p>

        <p className="text-xs text-gray-400">
        <a href="https://www.freepik.com/free-photo/abstract-computer-screen-with-marble-screensaver_19002926.htm#page=3&query=3D%20Illustration%20of%20computer%20transparent&position=0&from_view=search&track=ais" target='blank'>Image by rawpixel.com</a>,
        Image by <a href="https://www.freepik.com/free-photo/blank-white-notepad-surrounded-by-empty-cup-crumpled-paper-pen-earphone-black-backdrop_4068018.htm#query=laptop%20paper%20clips%20earphone%20on%20black%20desktop&position=7&from_view=search&track=ais" target='blank'>Freepik</a>
        </p>


          <p className="mt-2 text-sm leading-2 text-gray-600">         
          © All Rights Reserved
          </p>
        </div>
      </div>
    </div>
</>
  )
}
