import React, { useState } from 'react';

const Subscribe = () => {

  const [email, setEmail] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    // Temporary solution to clear the email input field and return
    setEmail('');
    return;

    // TODO: Add your logic here to handle the newsletter subscription
    // For example, you can use fetch or axios to send a request to your backend service
    // with the email data
    // Replace the API_ENDPOINT with your actual API endpoint
    const API_ENDPOINT = 'https://your-api-endpoint.com/subscribe';
    const data = { email };

    fetch(API_ENDPOINT, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('Subscription successful:', data);
        // Clear the email input field
        setEmail('');
      })
      .catch((error) => {
        console.log('Subscription error:', error);
      });
  };
  return (
    <>
      <div className="max-w-xl lg:max-w-lg ">
        <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">Subscribe to our newsletter.</h2>
        <p className="mt-4 text-lg leading-8 text-gray-300">
          Subscribe to stay updated on our latest information on job opportunities, technology updates and new products!
        </p>
        <form onSubmit={handleSubmit} className="">
          <div className="mt-6 flex max-w-md gap-x-4">
            <label htmlFor="email-address" className="sr-only">Email address</label>
            <input id="email-address"
              name="email"
              type="email"
              autoComplete="email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="min-w-0 flex-auto rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6" placeholder="Enter your email" />
            <button type="submit"
              className="flex-none rounded-md bg-gray-800 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm  focus:outline-none focus:ring focus:ring-gray-700">Subscribe</button>
          </div>
        </form>
      </div>

    </>
  );
}

export default Subscribe;
